.input {
    display: flex;
    width: 90%;
    position: relative;
    align-items: center;
}

.input_box {
    width: 100%;
    border-radius: 50px;
    padding: 20px 30px;
    font-size: 25px;
    border: none;
    transition: 0.2s;
    box-shadow: inset 0 0 5px black;
}

.input_box:focus {
    box-shadow: 0 0 10px 1000px rgba(0, 0, 0, 0.5);
    outline: none;
}

.input_submit {
    position: absolute;
    width: 50px;
    height: 50px;
    margin: 12px;
    border-radius: 50px;
    right: 0px;
    border: none;
    font-size: 15px;
    background-color: #2f74c0;
    color: white;
    transition: 0.2s all;
    box-shadow: 0 0 10px black;
}

.input_submit:hover {
    background-color: #388ae2;
}

.input_submit:active {
    transform: scale(0.8);
    box-shadow: 0 0 5px black;
}
.todos {
    display: flex;
    justify-content: space-around;
    width: 90%;
    margin-top: 15px;
}

.todos .pending{
    width: 100%;
    background-color: #dfdf8a;
    border: 1px black;
    padding: 5px;
}

.todos .done{
    width: 100%;
    background-color: rgb(77, 178, 77);
    border: 1px black;
    padding: 5px;
}
.singletodo{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.singletodo .actions{
    display: flex;
    gap: 14px;
}
